//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-956:_2188,_7780,_4492,_1092,_9156,_9152,_5000,_3896;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-956')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-956', "_2188,_7780,_4492,_1092,_9156,_9152,_5000,_3896");
        }
      }catch (ex) {
        console.error(ex);
      }