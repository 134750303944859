export default {
  TOTAL_HITS: 'PRODUCTS',

  SEARCH_BOX_PLACEHOLDER: 'Type to search...',

  SHOW_MORE: 'Show all',
  CURRENT_SEARCH: 'Filtered By:',
  FACET_DIALOG_CLEAR: 'Clear Filters',
  FACET_DIALOG_DONE: 'Apply Filters',

  GARAGE_SIZE: '<span class="garage-size #header-toolbar-cart-counter" key="garage-size">{{size}}</span>',
};
